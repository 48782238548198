<template>
  <!-- Error page-->
  <div class="misc-wrapper bg-wrapper">
    <b-link class="brand-logo">
      <b-img
        :src="logoUrl"
        alt="Payrite"
        style="width:150px"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <b-card>
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Pagina niet gevonden
          </h2>
          <p class="mb-2">
            Oops! De gevraagde URL is niet gevonden op deze server.
          </p>

          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{path:'/'}"
          >
            Terug
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BImg,
  BButton,
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BButton,
    BImg,
    BCard,
  },
  data() {
    return {
      logoImg: require('@/assets/images/logo/payrite.png'),
    }
  },
  computed: {
    logoUrl() {
      return this.logoImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.bg-wrapper{
  background: url("../../../assets/images/pages/lostpassword.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none !important;
}
</style>
